import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (isOpen) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(isOpen);
  };

  const menuItems = ['Home', 'Projects', 'About', 'Contact'].map((text) => {
    const to = `/${text.toLowerCase()}`;

    return (
      <ListItem 
        button 
        key={text} 
        component={RouterLink} 
        to={to} 
        selected={location.pathname === to} 
        onClick={toggleDrawer(false)}
      >
        <ListItemText primary={text} />
      </ListItem>
    );
  });

  const drawer = (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
    >
      <List>
        {menuItems}
      </List>
    </Drawer>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}

          {!isMobile && (
            <List sx={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
              {menuItems}
            </List>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile view */}
      {isMobile && drawer}
    </Box>
  );
};

export default NavBar;





