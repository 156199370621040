import React from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';

function Home() {
  // Video iframe properties
  const iframeProps = {
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/gwI3_rhOBOQ?si=kEF1hMfFBXPDRK_2",
    title: "YouTube video player",
    frameBorder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowFullScreen: true,
  };

  return (
    <div className="home-container">
      {/* React Helmet allows manipulation of the head of the document */}
      <Helmet>
        <title>Jie Guan's XR Universe</title>
        <meta name="description" content="Explore Jie's journey in merging reality with virtual dimensions through immersive XR narratives and innovative solutions in sports and entertainment." />
        {/* Open Graph tags for social media */}
        <meta property="og:title" content="Jie Guan's XR Universe" />
        <meta property="og:description" content="Dive into the innovative world of XR as Jie merges reality with virtual dimensions, creating immersive experiences across various sectors." />
        <meta property="og:image" content="URL_of_the_image_to_display" />
        <meta property="og:url" content="URL_of_your_page" />
        {/* Add additional tags as needed for other social media or SEO purposes */}
      </Helmet>

      <h1>Jie Guan's XR Universe</h1>
      <p>
        Hi, I'm Jie, an XR and emerging tech enthusiast with a passion for bridging reality and virtual dimensions. My journey encompasses vital research, creating immersive in-store XR narratives, and bringing innovation to the sports and entertainment sectors. Deeply fascinated by the metaverse and blockchain, I see a world where digital interaction transcends conventional boundaries. Join me in sculpting the landscape of our integrated future.
      </p>
      <div className="video-container">
        <iframe {...iframeProps}></iframe>
      </div>
    </div>
  );
}

export default Home;



