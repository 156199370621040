import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Home from './pages/Home/Home';
import Projects from './pages/Projects/Projects';
import Contact from './pages/Contact/Contact';
import MaintenanceNotice from './components/MaintenanceNotice/MaintenanceNotice';
import About from './pages/About/About';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />

        <div className="content-area">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />}/>
            <Route path="/projects" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>

          <MaintenanceNotice />
        </div>
        
      </div>
    </Router>
  );
}

export default App;


