import React from 'react';
import './MaintenanceNotice.css';

function MaintenanceNotice() {
  return (
    <p className="maintenance-notice">
      
      <a href="https://jieguann.format.com/project" target="_blank" rel="noopener noreferrer">In terms of this website being under maintenance, for more projects please visit here</a>.
    </p>
  );
}

export default MaintenanceNotice;
